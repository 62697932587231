import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectEaglemoss = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Eaglemoss - Desarrollo de ecommerce multitienda en Drupal" description="Se trata de un sitio web con ecommerce multitienda desarrollado en Drupal  con más de 15 tiendas para distintos países."/>
      <section className="jumbotron pt-3 pb-6 mb-0" style={{backgroundColor: '#D0E9FF'}}>
        <div className="hero-zone container">
          <div className="hero-text row justify-content-md-center">
            <div className="col-lg-6">
              <h1 className="font-weight-bold diplay-4">Eaglemoss</h1>
              <p className="h3">Desarrollo de e-commerce multi-tienda / multi-pais líder en el sector de figuras coleccionables.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-3 bg-light text-left" id="features">
        <div className="container pb-5">
          <div className="row text-left">
            <div className="col-md-8 col-sm-12">
              <span className="badge badge-pill badge-secondary p-2 mr-2 mb-4">Desarrollo Drupal</span>
              <h2>
                <strong className="text-left">Puntos clave</strong>
              </h2>
              <div className="lead pt-4">
                <strong className="texto-destacado">E-commerce a medida.strong</strong>
                <p>Debido al alto grado personalización del proceso de compra y el display de productos, en vez de usar Drupal Commerce, se optó por desarrollar un sistema e-commerce Drupal desde cero.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Arquitectura multi-dominio con más de 10 países.</strong>
                <p>Eaglemos vende en todo el mundo y los sites para cada región son muy similares. Por ello, se implementó una arquitectura basada en el <em>Drupal Domain Access</em>, donde se emplea un único codebase y una uńica base de datos para todos los sitios, lo que facilitar las actualizaciones y el control sobre los sitios, así como herramientas de clonado de productos entre regiones.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Integración con diferentes medios de pago de cada país.</strong>
                <p>Además de Paypal, Stripe, etc, algunos países disponen de sus propios métodos de pago (ej. Sofort), para los cuáles se desarrollo una integración, puesto que en el momento de realizar el proyecto no disponían de integración en Drupal.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Integración con su ERP.</strong>
                <p>Eaglemoss es una gran empresa que gestiona multitud de procesos y personas. La web debía ser parte de esos procesos, por lo que se desarrollo una integración completa de pedidos, envíos, clientes y stock con su CRM.</p>
              </div>
              <div className="lead pt-4 pb-5">
                <strong className="texto-destacado">Integración con GTM datalayer.</strong>
                <p>Se usó datalayer para recopilar información de las acciones de los usuarios en la web, y su respuesta ante distintas páginas de producto y procesos de <em>checkout</em>.</p>
              </div>
            </div>
            <div className="col-md-3 offset-md-0 offset-lg-1 col-sm-12">
              <div className="colaboradores">
                <h2 className="pt-lg-5">
                  <strong className="text-left pt-0">Colaboradores</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">En este proyecto colaboramos con <a href="https:avatarnewyork.com/" target="_blank" rel="noopener noreferrer">Avatar New York</a>, que llevaron la gestión del proyecto y se encargaron del diseño visual.</p>
              <div className="cliente-proyecto">
                <h2 className="pt-5">
                  <strong className="text-left pt-0">Cliente</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">Editor líder de piezas con más de 40 años de experiencia y pasión por los productos coleccionables creativos e innovadores.</p>
              <div className="btn-project">
                <a className="btn mr-4 btn-lg btn-outline-primary" href="https://en-us.eaglemoss.com/">Sitio web →</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="jumbotron pb-5 text-left bg-white pt-0 mt-n6" id="features">
        <div className="container">
          <div className="row text-left">
            <div className="col-md-12 col-12 col-sm-12">
              <h2 className="pt-4 pb-4">
                <strong>El proyecto en imágenes</strong>
              </h2>
            </div>
            <div className='embed-responsive embed-responsive-16by9'>
              <iframe className="project-video embed-responsive-item" title="Video" width={1200} height={674} src="https://player.vimeo.com/video/412514405?autoplay=1&loop=1&autopause=0&muted=1&background=1"></iframe>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProjectEaglemoss
